import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import TopbarButtons from "./TopbarButtons";
import { makeStyles } from "@mui/styles";
import logo from "../../../assets/images/oggito_logo.png";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appbar: {
    [theme.breakpoints.up("md")]: {
      padding: "0rem 1rem"
    }
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      marginRight: "1rem"
    }
  },
  container: {
    flexGrow: 1,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "space-between"
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start"
    }
  }
}));

function Topbar({ onSidebarToggleClick }) {
  const classes = useStyles();

  const mdUpHidden = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mdDownHidden = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <AppBar position="fixed" className={classes.appbar} elevation={0}>
      <Toolbar>
        <Grid container={true} className={classes.container}>
          {!mdUpHidden && (
            <IconButton onClick={onSidebarToggleClick} color="inherit" size="large">
              <MenuIcon />
            </IconButton>
          )}
          <Button component={Link} to="/" className={classes.logo} color="inherit">
            <img width="105" src={logo} alt="Oggito Logo" />
          </Button>
          {!mdDownHidden && <TopbarButtons />}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;
