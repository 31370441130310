import React, { createContext, useContext } from "react";
import { useAxiosService } from "./AxiosService";

const CategoriesContext = createContext(null);

export function CategoriesService(props) {
  const axios = useAxiosService();

  function GetFeaturedCategories(cancelToken) {
    const config = {
      method: "get",
      url: "categories/featured/contents",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetCategoryContents(cancelToken, slug, page) {
    const config = {
      method: "get",
      url: `categories/${slug}/contents`,
      params: {
        page: page
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  const value = {
    GetFeaturedCategories: GetFeaturedCategories,
    GetCategoryContents: GetCategoryContents
  };

  return <CategoriesContext.Provider value={value}>{props.children}</CategoriesContext.Provider>;
}

export function useCategoriesService() {
  return useContext(CategoriesContext);
}
