import React, { createContext, useContext } from "react";
import { useAxiosService } from "./AxiosService";

const AdvertisementContext = createContext(null);

export function AdvertisementService(props) {
  const axios = useAxiosService();

  function Update(cancelToken, advertisementId) {
    const config = {
      method: "patch",
      url: `adm/${advertisementId}`,
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  const value = {
    Update: Update
  };

  return (
    <AdvertisementContext.Provider value={value}>{props.children}</AdvertisementContext.Provider>
  );
}

export function useAdvertisementService() {
  return useContext(AdvertisementContext);
}
