import React, { createContext, useContext } from "react";
import { useAxiosService } from "./AxiosService";

const UsersContext = createContext(null);

export function UsersService(props) {
  const axios = useAxiosService();

  function Get(cancelToken) {
    const config = {
      method: "get",
      url: "users",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function UpdateInformation(cancelToken, firstName, lastName, city) {
    const config = {
      method: "put",
      url: "users",
      data: {
        firstName: firstName,
        lastName: lastName,
        city: city
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function UpdatePassword(cancelToken, oldPassword, newPassword) {
    const config = {
      method: "patch",
      url: "users",
      data: {
        oldPassword: oldPassword,
        newPassword: newPassword
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetContents(cancelToken) {
    const config = {
      method: "get",
      url: "users/contents",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function AddContent(cancelToken, contentId) {
    const config = {
      method: "post",
      url: `users/contents/${contentId}`,
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function DeleteContent(cancelToken, contentId) {
    const config = {
      method: "delete",
      url: `users/contents/${contentId}`,
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  const value = {
    Get: Get,
    UpdateInformation: UpdateInformation,
    UpdatePassword: UpdatePassword,
    GetContents: GetContents,
    AddContent: AddContent,
    DeleteContent: DeleteContent
  };

  return <UsersContext.Provider value={value}>{props.children}</UsersContext.Provider>;
}

export function useUsersService() {
  return useContext(UsersContext);
}
