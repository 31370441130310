import React, { createContext, useContext } from "react";
import { useAxiosService } from "./AxiosService";

const AccountContext = createContext(null);

export function AccountService(props) {
  const axios = useAxiosService();

  function Signup(cancelToken, email, password, firstName, lastName, city, gender) {
    const config = {
      method: "post",
      url: "account/signup",
      data: {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        city: city,
        gender: gender
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function Login(cancelToken, email, password) {
    const config = {
      method: "post",
      url: "account/login",
      data: {
        email: email,
        password: password
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetAccessToken(cancelToken, refreshToken) {
    const config = {
      method: "post",
      url: "account/access-token",
      data: {
        refreshToken: refreshToken
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function SendPasswordReset(cancelToken, email) {
    const config = {
      method: "post",
      url: "account/send-password-reset",
      data: {
        email: email
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function ResetPassword(cancelToken, userId, passwordResetToken, newPassword) {
    const config = {
      method: "post",
      url: "account/reset-password",
      data: {
        userId: userId,
        passwordResetToken: passwordResetToken,
        newPassword: newPassword
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  const value = {
    Signup: Signup,
    Login: Login,
    GetAccessToken: GetAccessToken,
    SendPasswordReset: SendPasswordReset,
    ResetPassword: ResetPassword
  };

  return <AccountContext.Provider value={value}>{props.children}</AccountContext.Provider>;
}

export function useAccountService() {
  return useContext(AccountContext);
}
