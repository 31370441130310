import React from "react";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

const TopbarMenuButtonItem = ({ button, onClick }) => {
  const item = (
    <MenuItem component={Link} to={button.slug} onClick={() => onClick()} key={button.name}>
      <Typography variant="body2">
        <b>{button.name}</b>
      </Typography>
    </MenuItem>
  );

  const childItems =
    button.children &&
    button.children.length > 0 &&
    button.children.map((c) => (
      <MenuItem component={Link} to={c.slug} onClick={() => onClick()} key={c.name}>
        <Typography variant="body2">
          <b>- {c.name}</b>
        </Typography>
      </MenuItem>
    ));

  return (
    <>
      {item}
      {childItems}
    </>
  );
};

export default TopbarMenuButtonItem;
