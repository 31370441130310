import React, { createContext, useContext } from "react";
import { useAxiosService } from "./AxiosService";

const AuthorsContext = createContext(null);

export function AuthorsService(props) {
  const axios = useAxiosService();

  function GetFeaturedAuthorsContents(cancelToken) {
    const config = {
      method: "get",
      url: "authors/featured/contents",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetOggitoAuthors(cancelToken) {
    const config = {
      method: "get",
      url: "authors/oggito",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetOggitoAuthorsContents(cancelToken) {
    const config = {
      method: "get",
      url: "authors/oggito/contents",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetAuthorContents(cancelToken, authorId, page) {
    const config = {
      method: "get",
      url: `authors/${authorId}/contents`,
      params: {
        page: page
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  const value = {
    GetFeaturedAuthorsContents: GetFeaturedAuthorsContents,
    GetOggitoAuthorsContents: GetOggitoAuthorsContents,
    GetAuthorContents: GetAuthorContents,
    GetOggitoAuthors: GetOggitoAuthors
  };

  return <AuthorsContext.Provider value={value}>{props.children}</AuthorsContext.Provider>;
}

export function useAuthorsService() {
  return useContext(AuthorsContext);
}
