import React, { createContext, useContext } from "react";
import { useAxiosService } from "./AxiosService";

const ArchiveContext = createContext(null);

export function ArchiveService(props) {
  const axios = useAxiosService();

  function GetYears(cancelToken) {
    const config = {
      method: "get",
      url: "archive/years",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetYearMonths(cancelToken, year) {
    const config = {
      method: "get",
      url: `archive/years/${year}`,
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetYearMonthContents(cancelToken, year, month) {
    const config = {
      method: "get",
      url: `archive/years/${year}/months/${month}`,
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  const value = {
    GetYears: GetYears,
    GetYearMonths: GetYearMonths,
    GetYearMonthContents: GetYearMonthContents
  };

  return <ArchiveContext.Provider value={value}>{props.children}</ArchiveContext.Provider>;
}

export function useArchiveService() {
  return useContext(ArchiveContext);
}
