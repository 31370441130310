import React, { createContext, useContext } from "react";
import { useAxiosService } from "./AxiosService";

const ContentsContext = createContext(null);

export function ContentsService(props) {
  const axios = useAxiosService();

  function GetFeaturedContents(cancelToken) {
    const config = {
      method: "get",
      url: "contents/featured",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetHomeTimelineContents(cancelToken, page) {
    const config = {
      method: "get",
      url: "contents/home",
      params: {
        page: page
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetInterestingContents(cancelToken) {
    const config = {
      method: "get",
      url: "contents/interesting",
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetContent(cancelToken, contentId) {
    const config = {
      method: "get",
      url: `contents/${contentId}`,
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function GetSearchContents(cancelToken, query, page) {
    const config = {
      method: "get",
      url: "contents/search",
      params: {
        query: query,
        page: page
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  function AddContentComment(cancelToken, contentId, commentText) {
    const config = {
      method: "post",
      url: `contents/${contentId}/comments`,
      data: {
        commentText: commentText
      },
      cancelToken: cancelToken
    };

    return axios.send(config);
  }

  const value = {
    GetFeaturedContents: GetFeaturedContents,
    GetHomeTimelineContents: GetHomeTimelineContents,
    GetInterestingContents: GetInterestingContents,
    GetSearchContents: GetSearchContents,
    GetContent: GetContent,
    AddContentComment: AddContentComment
  };

  return <ContentsContext.Provider value={value}>{props.children}</ContentsContext.Provider>;
}

export function useContentsService() {
  return useContext(ContentsContext);
}
