import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const Home = lazy(() => import("../views/Home/Home"));
const Category = lazy(() => import("../views/Category/Category"));
const Content = lazy(() => import("../views/Content/Content"));
const Author = lazy(() => import("../views/Author/Author"));
const NotFound = lazy(() => import("../views/NotFound/NotFound"));
const About = lazy(() => import("../views/About/About"));
const Tag = lazy(() => import("../views/Tag/Tag"));
const Policies = lazy(() => import("../views/Policies/Policies"));
const Agreement = lazy(() => import("../views/Agreement/Agreement"));
const Contact = lazy(() => import("../views/Contact/Contact"));
const SendContent = lazy(() => import("../views/SendContent/SendContent"));
const SendVideo = lazy(() => import("../views/SendVideo/SendVideo"));
const Authors = lazy(() => import("../views/Authors/Authors"));
const Signup = lazy(() => import("../views/Signup/Signup"));
const Login = lazy(() => import("../views/Login/Login"));
const UpdateInformation = lazy(() => import("../views/UpdateInformation/UpdateInformation"));
const MyLikes = lazy(() => import("../views/MyLikes/MyLikes"));
const ChangePassword = lazy(() => import("../views/ChangePassword/ChangePassword"));
const Logout = lazy(() => import("../views/Logout/Logout"));
const ForgotPassword = lazy(() => import("../views/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("../views/ResetPassword/ResetPassword"));
const Search = lazy(() => import("../views/Search/Search"));
const ArchiveYears = lazy(() => import("../views/Archive/ArchiveYears"));
const ArchiveYearMonths = lazy(() => import("../views/Archive/ArchiveYearMonths"));
const ArchiveYearMonthContents = lazy(() => import("../views/Archive/ArchiveYearMonthContents"));

function Routes() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path="/uyelik/kayit-ol" component={Signup} />
        <Route exact path="/uyelik/giris-yap" component={Login} />
        <Route exact path="/uyelik/sifremi-unuttum" component={ForgotPassword} />
        <Route exact path="/uyelik/sifre-sifirla" component={ResetPassword} />

        <PrivateRoute exact path="/uyelik/bilgilerim" component={UpdateInformation} />
        <PrivateRoute exact path="/uyelik/begendiklerim" component={MyLikes} />
        <PrivateRoute exact path="/uyelik/sifre-degistir" component={ChangePassword} />
        <PrivateRoute exact path="/uyelik/cikis-yap" component={Logout} />

        <Route exact path="/icerikler/:contentSlug/:contentId" component={Content} />
        <Route exact path="/yazarlar/:authorSlug/:authorId" component={Author} />
        <Route exact path="/kategoriler/:categorySlug" component={Category} />
        <Route exact path="/hakkimizda" component={About} />
        <Route exact path="/kunye" component={Tag} />
        <Route exact path="/yayin-ilkeleri" component={Policies} />
        <Route exact path="/kullanici-sozlesmesi" component={Agreement} />
        <Route exact path="/iletisim" component={Contact} />
        <Route exact path="/arsiv/:year/:month" component={ArchiveYearMonthContents} />
        <Route exact path="/arsiv/:year" component={ArchiveYearMonths} />
        <Route exact path="/arsiv" component={ArchiveYears} />
        <Route exact path="/yazi-gonder" component={SendContent} />
        <Route exact path="/video-gonder" component={SendVideo} />
        <Route exact path="/yazarlar" component={Authors} />
        <Route exact path="/arama" component={Search} />
        <Route exact path="/" component={Home} />
        <Route exact path="/404" component={NotFound} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
