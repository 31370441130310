import React, { createContext, useContext, useEffect, useState } from "react";

const EnvironmentContext = createContext(null);

export function useEnvironmentGlobal() {
  return useContext(EnvironmentContext);
}

export function EnvironmentGlobal({ children }) {
  const [environment, setEnvironment] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [clientUrl, setClientUrl] = useState("");

  useEffect(() => {
    setEnvironment(process.env.REACT_APP_ENVIRONMENT ?? "");
    setApiUrl(process.env.REACT_APP_API_URL ?? "");
    setClientUrl(process.env.REACT_APP_CLIENT_URL ?? "");
  }, []);

  const value = {
    environment: environment,
    apiUrl: apiUrl,
    clientUrl: clientUrl
  };

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>;
}
