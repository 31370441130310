import React from "react";
import { AuthorsService } from "./AuthorsService";
import { CategoriesService } from "./CategoriesService";
import { ContentsService } from "./ContentsService";
import { AccountService } from "./AccountService";
import { SnackbarGlobal } from "../globals/SnackbarGlobal";
import { AuthGlobal } from "../globals/AuthGlobal";
import { UsersService } from "./UsersService";
import { LoadingGlobal } from "../globals/LoadingGlobal";
import { ArchiveService } from "./ArchiveService";
import { AdvertisementService } from "./AdvertisementService";
import { EnvironmentGlobal } from "../globals/EnvironmentGlobal";
import { FacebookGlobal } from "../globals/FacebookGlobal";

function Services({ children }) {
  return (
    <SnackbarGlobal>
      <LoadingGlobal>
        <AuthGlobal>
          <EnvironmentGlobal>
            <FacebookGlobal>
              <AccountService>
                <UsersService>
                  <AuthorsService>
                    <CategoriesService>
                      <AdvertisementService>
                        <ArchiveService>
                          <ContentsService>{children}</ContentsService>
                        </ArchiveService>
                      </AdvertisementService>
                    </CategoriesService>
                  </AuthorsService>
                </UsersService>
              </AccountService>
            </FacebookGlobal>
          </EnvironmentGlobal>
        </AuthGlobal>
      </LoadingGlobal>
    </SnackbarGlobal>
  );
}

export default Services;
