import { colors } from "./colors";
import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Arial",
      "sans-serif"
    ].join(",")
  },

  colorSchemes: {
    light: {
      palette: {
        background: {
          default: grey[50]
        },
        primary: {
          main: grey[900]
        },
        secondary: {
          main: grey[700]
        },
        tonalOffset: 0.1
      }
    }
  },

  mixins: {
    toolbar: {
      minHeight: "4.5rem"
    }
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.primary
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            boxShadow: "none"
          },
          "&:active": {
            boxShadow: "none"
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: grey[200],
          backgroundColor: colors.primary
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          color: grey[200]
        },
        paper: {
          color: grey[200],
          backgroundColor: colors.primary
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: grey[300]
        }
        // wave: {
        //   "&::after": {
        //     background: `linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.15), transparent)`
        //   }
        // }
      }
    }
  },

  shape: {
    // borderRadius: 0
  }
});

export default theme;
