import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext(null);

export function useAuthGlobal() {
  return useContext(AuthContext);
}

export function AuthGlobal({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const existingRefreshToken = getRefreshToken();
    const existingAccessToken = getAccessToken();

    if (existingRefreshToken && existingAccessToken)
      login(existingRefreshToken, existingAccessToken);
    else logout();
  }, []);

  function login(refreshToken, accessToken) {
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("accessToken", accessToken);
    setIsAuthenticated(true);
  }

  function logout() {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
  }

  function getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  function getAccessToken() {
    return localStorage.getItem("accessToken");
  }

  const value = {
    getRefreshToken: getRefreshToken,
    getAccessToken: getAccessToken,
    login: login,
    logout: logout,
    isAuthenticated: isAuthenticated
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
